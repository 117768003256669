'use client';

import { showsFiltersAtom } from '@/lib/atoms/shows-filters';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import SearchIcon from '/public/svg/search.svg';
import CloseIcon from '/public/svg/pbs-close-white.svg';
import styles from '@/app/shows/ShowsLandingFilters/TitleSearch.module.scss';
function MobileFilters() {
  const [showsFilters, setShowsFilters] = useAtom(showsFiltersAtom);
  const [debouncedTextInput, setDebouncedTextInput] = useState(showsFilters.search);
  useEffect(() => {
    // remove accents/diacritics and replace them inline
    // courtesy of https://stackoverflow.com/a/37511463
    const normalizedTextInput = debouncedTextInput?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    if (normalizedTextInput === showsFilters.search) {
      return;
    }
    const handler = setTimeout(() => {
      setShowsFilters(previousFilters => {
        return {
          ...previousFilters,
          search: normalizedTextInput
        };
      });
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedTextInput]);
  return <div className={styles.text_input} data-sentry-component="MobileFilters" data-sentry-source-file="TitleSearch.tsx">
      <label className={styles.legend} htmlFor='shows_landing__filter_by_title'>
        Filter by Title:
      </label>

      {debouncedTextInput?.length !== 0 && <CloseIcon className={`${styles.text_input_icon} ${styles.text_input_icon_close}`} aria-label='Clear text input button' onClick={() => setDebouncedTextInput('')} />}

      <input type='text' id='shows_landing__filter_by_title' className={styles.title_filter_input} value={debouncedTextInput} onChange={e => setDebouncedTextInput(e.target.value)} placeholder='Search By Show Title' />

      {debouncedTextInput?.length === 0 && <SearchIcon className={styles.text_input_icon} />}
  </div>;
}
export default MobileFilters;