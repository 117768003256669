import { GENRE_FILTER, SOURCE_FILTER, SORT_OPTION } from "@/lib/types/graphql/searchShows"

export interface FilterObject {
  genre: keyof typeof GENRE_FILTER
  sortBy: keyof typeof SORT_OPTION
  source: keyof typeof SOURCE_FILTER
  stationId?: string | null
  search?: string
}

export const DefaultShowsFilters = {
  genre: GENRE_FILTER.ALL,
  sortBy: SORT_OPTION.POPULAR,
  source: SOURCE_FILTER.ALL,
  stationId: null,
  search: ''
} as const
