'use client';

import { GENRES, SOURCE_FILTERS, SORT_BY, ALL_POSSIBLE_VALUES, SOURCE_FILTER } from '@/lib/types/graphql/searchShows';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { showsFiltersAtom } from '@/lib/atoms/shows-filters';
import ControlSlidersIcon from '/public/svg/control-sliders.svg';
import CloseIcon from '/public/svg/pbs-close-white.svg';
import styles from '@/app/shows/ShowsLandingFilters/MobileFilters.module.scss';
import { FilterObject } from '@/app/shows/helpers';
export interface MobileFiltersProps {
  station?: {
    id?: string;
    commonName?: string;
  };
}
function MobileFilters(props: MobileFiltersProps) {
  const [showsFilters, setShowsFilters] = useAtom(showsFiltersAtom);
  const [renderFilters, setRenderFilters] = useState(false);
  function updateFilter(key: keyof FilterObject, value: ALL_POSSIBLE_VALUES) {
    setShowsFilters(previousFilters => {
      return {
        ...previousFilters,
        [key]: value
      };
    });
  }
  function getLabel(str: string) {
    if (str === 'Local Shows' && props.station?.commonName) {
      return `Only ${props.station.commonName} Shows`;
    }
    return str;
  }
  return <>
    <div className={styles.mobile_controls}>
      <button className={styles.mobile_filters_button} onClick={() => setRenderFilters(true)}>
        Filter
        <ControlSlidersIcon data-sentry-element="ControlSlidersIcon" data-sentry-source-file="MobileFilters.tsx" />
      </button>

      <select className={styles.mobile_sort_by_select} name='sort-by' onChange={e => updateFilter('sortBy', (e.target.value as ALL_POSSIBLE_VALUES))} value={showsFilters.sortBy}>
        {SORT_BY.map((sortBy, i) => {
          return <option value={sortBy.value} key={i}>Sort By: {getLabel(sortBy.label)}</option>;
        })}
      </select>
    </div>
    <div className={styles.linear_shadow}></div>
    {renderFilters ? <form className={styles.mobile_filters} action='#' onSubmit={e => e.preventDefault()}>
          <div className={styles.mobile_filters_title}>
            <h2>Filter</h2>
            <CloseIcon className={styles.filters_close_icon} onClick={() => setRenderFilters(false)} />
          </div>

          <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>
            {props.station?.id ? 'Local & ' : ''}
            Featured in Passport:
          </legend>

          {SOURCE_FILTERS.map((source, i) => <span className={styles.radio} key={i}>
              <input type='radio' name='filter' value={source.value} aria-checked={showsFilters.source === source.value} checked={showsFilters.source === source.value} onChange={() => updateFilter('source', source.value)} />
              <label className={styles.radio_label} htmlFor={source.value} onClick={() => updateFilter('source', source.value)}>{source.label}</label>
          </span>)}
        </fieldset>

        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>Genre</legend>

          {GENRES.map((genre, i) => <span className={styles.radio} key={i}>
              <input type='radio' name='genre' value={genre.value} aria-checked={showsFilters.genre === genre.value} checked={showsFilters.genre === genre.value} onChange={() => updateFilter('genre', genre.value)} />
              <label className={styles.radio_label} htmlFor={genre.value} onClick={() => updateFilter('genre', genre.value)}>{genre.label}</label>
            </span>)}
        </fieldset>
      </form> : <></>}
    </>;
}
export default MobileFilters;