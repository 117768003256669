'use client';

import styles from './ShowPosterGrid.module.scss';
import { ShowDetails, ShowRowContent } from '@/lib/types/api/show-data';
import { FeaturedShowsContent } from '@/lib/types/api/home-data';
import ShowPoster from '@/components/ShowPoster/ShowPoster';
import { ShowNode } from '@/lib/types/graphql/searchShowsResponse';
export interface ShowPosterGridProps {
  shows: ShowDetails[] | ShowRowContent[] | FeaturedShowsContent[] | ShowNode[];
}
export default function ShowPosterGrid(props: ShowPosterGridProps) {
  const {
    shows
  } = props;
  return <div className={styles.show_poster_grid} data-sentry-component="ShowPosterGrid" data-sentry-source-file="ShowPosterGrid.tsx">
      {shows.map((show, i: number) => {
      return <ShowPoster key={i} show={show} />;
    })}
    </div>;
}