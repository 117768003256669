import graphql, { GraphQLOptions, GraphQLPlatformType } from '@/lib/content-services/graphql';
import { GENRE_FILTER, SOURCE_FILTER } from '@/lib/types/graphql/searchShows';
import { FilterObject } from '@/lib/types/showsFilters';
import { SearchShowsResponse } from '@/lib/types/graphql/searchShowsResponse'

const SHOWS = `
  query SearchShowsQuery($first: Int!, $ordering: AllShowsOrdering!, $title: String, $genre: Genre, $source: AllShowsSource, $after: String) {
    searchShows(
      first: $first
      ordering: $ordering
      title: $title
      genre: $genre
      source: $source
      after: $after
    ) {
      edges {
        cursor
        node {
          cid
          description: descriptionLong
          genre
          image: poster2x3ImageUrl
          title
          slug
          website: producerUrl
          item_type: __typename
        }
        __typename
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        __typename
      }
      __typename
    }
  }
`

export type FetchShowsArgs = {
  platform: GraphQLPlatformType;
  filters: FilterObject;
  after?: string | null;
  stationId?: string | null;
  userId?: string;
}

interface ShowsQueryVariables {
  first: number;
  ordering: string;
  genre: string | null;
  source: string | null;
  title?: string | null;
  after?: string;
}

async function fetchShows(args: FetchShowsArgs, abortController?: AbortController) {
  const { platform, filters, after, stationId, userId } = args
  let genre, source = null

  if(filters.genre === GENRE_FILTER.ALL) {
    genre = null
  } else {
    genre = filters.genre
  }

  if(filters.source === SOURCE_FILTER.ALL) {
    source = null
  } else {
    source = filters.source
  }

  const variables : ShowsQueryVariables = {
    first: 50,
    ordering: filters.sortBy,
    genre: genre,
    source: source,
    title: filters.search
  }

  if(after) {
    variables.after = after
  }

  const options: GraphQLOptions = {
    query: SHOWS,
    variables,
    platform
  }

  if(userId) {
    options.userId = userId
  } else if(stationId) {
    options.stationId = stationId
  }

  const response: { data: SearchShowsResponse } = await graphql(options, { abortController });

  return response;
}

export default fetchShows
