import { GENRES, GENRE_FILTER, SOURCE_FILTER, SOURCE_FILTERS, SORT_BY, SORT_OPTION } from "@/lib/types/graphql/searchShows"

const validSearchParams = [
  'search',
  'genre',
  'source',
  'sortBy',
  'stationId'
] as const

const searchParamToListMap = {
  genre: GENRES,
  source: SOURCE_FILTERS,
  sortBy: SORT_BY
} as const

export interface FilterObject {
  genre: keyof typeof GENRE_FILTER
  sortBy: keyof typeof SORT_OPTION
  source: keyof typeof SOURCE_FILTER
  stationId?: string | null
  search?: string
}

export function constructFilteredURL(filters: FilterObject) {
  const searchParams = new URLSearchParams()

  Object.entries(filters).forEach(filter => {
    const [name, value] = filter

    if(name === 'stationId' && value) {
      searchParams.append(name, value)
    }

    if(name === 'search') {
      searchParams.append(name, value)
    }

    if(name === 'genre' || name === 'sortBy' || name === 'source') {
      const searchValue = searchParamToListMap[name].find(x => x.value === value)!.searchValue
      searchParams.append(name, searchValue)
    }
  })

  return `/shows?${searchParams.toString()}`
}

export function produceFiltersFromURL(searchParams: URLSearchParams): FilterObject {
  const filters = validSearchParams.map(param => {
    const value = searchParams.get(param)

    if(param === 'stationId' && !value) return []

    return [param, validValueForFilter(param, value)]
  })

  return Object.fromEntries(filters)
}

type ValidLists = typeof GENRES | typeof SOURCE_FILTERS | typeof SORT_BY

function getValue(list: ValidLists, param: string, value: string) {
  const item = list.find(x => x.searchValue === value)
  if(!item) return getDefaultValueForFilter(param)
  return item.value
}

function validValueForFilter(param: string, value: string | null) {
  if(param === 'search') {
    if((typeof value) === 'string') {
      return value
    } else {
      return ''
    }
  }

  if(param === 'stationId') return value

  if(param === 'genre' || param === 'sortBy' || param === 'source') {
    return getValue(searchParamToListMap[param] as ValidLists, param, value!)
  }
}

function getDefaultValueForFilter(param: string) {
  switch (param) {
    case 'genre':
      return GENRE_FILTER.ALL
  
    case 'source':
      return SOURCE_FILTER.ALL

    case 'sortBy':
      return SORT_OPTION.POPULAR
    
    default:
      return null
  }
}
