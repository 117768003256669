import { type RefObject, useRef, useEffect } from 'react'

const getElementTopPosition = (element: HTMLElement): number => {
  if (element && element.offsetParent) {
    return element.offsetTop + getElementTopPosition(element.offsetParent as HTMLElement);
  }
  return 0;
}

const elementIsFullyScrolled = (element: HTMLElement, offset = 150): boolean => {
  const scrolledFromTop = window.scrollY;

  const pixelsFromBottomOfElement =
    getElementTopPosition(element) +
    element.offsetHeight -
    scrolledFromTop -
    window.innerHeight;

  if (pixelsFromBottomOfElement < offset) {
    return true;
  }
  return false;
};

const useInfiniteScroll = <TElement extends HTMLElement>(ref: RefObject<TElement>) => {
  const callbackRef = useRef<() => Promise<void> | void>();

  useEffect(() => {
    const handleScroll = () => {
      if(ref.current && callbackRef.current) {
        if (elementIsFullyScrolled(ref.current)) {
          callbackRef.current();
        }
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const onInfiniteScroll = (callback: () => Promise<void> | void) => {
    callbackRef.current = callback;
  };

  return onInfiniteScroll;
};

export default useInfiniteScroll
