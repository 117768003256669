enum GENRE_FILTER {
  ALL = 'ALL',
  NEWS_AND_PUBLIC_AFFAIRS = 'NEWS_AND_PUBLIC_AFFAIRS',
  ARTS_AND_MUSIC = 'ARTS_AND_MUSIC',
  CULTURE = 'CULTURE',
  DRAMA = 'DRAMA',
  FOOD = 'FOOD',
  HISTORY = 'HISTORY',
  HOME_AND_HOWTO = 'HOME_AND_HOWTO',
  INDIE_FILMS = 'INDIE_FILMS',
  SCIENCE_AND_NATURE = 'SCIENCE_AND_NATURE'
}

enum SOURCE_FILTER {
  ALL = 'ALL',
  FEATURED_PASSPORT = 'FEATURED_PASSPORT',
  LOCAL = 'LOCAL'
}

enum SORT_OPTION {
  POPULAR = 'POPULAR',
  ALPHABETICAL = 'ALPHABETICAL'
}

const GENRES = [
  {
    label: 'All',
    value: GENRE_FILTER.ALL,
    searchValue: 'all-genres'
  },
  {
    label: 'Arts & Music',
    value: GENRE_FILTER.ARTS_AND_MUSIC,
    searchValue: 'arts-and-music'
  },
  {
    label: 'Culture',
    value: GENRE_FILTER.CULTURE,
    searchValue: 'culture'
  },
  {
    label: 'Drama',
    value: GENRE_FILTER.DRAMA,
    searchValue: 'drama'
  },
  {
    label: 'Food',
    value: GENRE_FILTER.FOOD,
    searchValue: 'food'
  },
  {
    label: 'History',
    value: GENRE_FILTER.HISTORY,
    searchValue: 'history'
  },
  {
    label: 'Home & How To',
    value: GENRE_FILTER.HOME_AND_HOWTO,
    searchValue: 'home-and-howto'
  },
  {
    label: 'Indie Films',
    value: GENRE_FILTER.INDIE_FILMS,
    searchValue: 'indie-films'
  },
  {
    label: 'News & Public Affairs',
    value: GENRE_FILTER.NEWS_AND_PUBLIC_AFFAIRS,
    searchValue: 'news-and-public-affairs'
  },
  {
    label: 'Science & Nature',
    value: GENRE_FILTER.SCIENCE_AND_NATURE,
    searchValue: 'science-and-nature'
  }
]

const SOURCE_FILTERS = [
  {
    label: 'All',
    value: SOURCE_FILTER.ALL,
    searchValue: 'all-sources'
  },
  {
    label: 'Featured Passport Shows',
    value: SOURCE_FILTER.FEATURED_PASSPORT,
    searchValue: 'passport-library'
  },
  {
    label: 'Local Shows',
    value: SOURCE_FILTER.LOCAL,
    searchValue: 'station-only'
  }
]

const SORT_BY = [
  {
    label: 'Popular',
    value: SORT_OPTION.POPULAR,
    searchValue: 'popular'
  },
  {
    label: 'A-Z',
    value: SORT_OPTION.ALPHABETICAL,
    searchValue: 'alphabetical'
  }
]

type GENRE_VALUES = `${GENRE_FILTER}`
type SOURCE_FILTER_VALUES = `${SOURCE_FILTER}`
type SORT_OPTION_VALUES = `${SORT_OPTION}`

export type ALL_POSSIBLE_VALUES = GENRE_VALUES | SOURCE_FILTER_VALUES | SORT_OPTION_VALUES

export {
  GENRE_FILTER,
  GENRES,
  SOURCE_FILTER,
  SOURCE_FILTERS,
  SORT_OPTION,
  SORT_BY
}
